import React from 'react';
import Helmet from 'react-helmet';
import Page from '../components/page';
import Section from '../components/section';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import StyledLink from '../components/styled-link';

const NotFoundPage = ({ location }) => {
  return (
    <Page location={location}>
      <main>
        <Helmet>
          {/* Use the default description */}
          {/* <meta name="description" content=""/> */}
        </Helmet>
        <Section>
          <LayoutRow>
            <MainColumn>
              <h1>Page not found</h1>
              <p>But maybe you'd be interested in our <StyledLink to="/">work</StyledLink> or learning more about the webologists on our <StyledLink to="/contact">contact</StyledLink> page.</p>
            </MainColumn>
          </LayoutRow>
        </Section>
      </main>
    </Page>
  );
};

export default NotFoundPage;
